<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="accounttaxes"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.code`]="{ item }">
            <v-btn
              :to="{ name: 'accounttaxes.update', params: { id: item.id } }"
              color="primary"
              text
            >
              {{ item.code }}
            </v-btn>
          </template>

          <template v-slot:[`item.tax`]="{ item }">
            {{ item.tax.name }}
          </template>

          <template v-slot:[`item.invoice_movement_type`]="{ item }">
            {{ item.invoice_movement_type.name }}
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <accounttaxes-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccounttaxesMixin from "@/modules/accounttaxes/mixins/AccounttaxesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import AccounttaxesForm from "@/modules/accounttaxes/components/Form.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";

@Component({
  components: {
    ActiveIcon,
    AccounttaxesForm,
  },
})
export default class AccounttaxesList extends Mixins(AccounttaxesMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "account.code", value: "code" },
      { text: "account.name", value: "name" },
      { text: "taxtype", value: "tax" },
      { text: "invoice.movement.type", value: "invoice_movement_type" },
    ];
    this.setDTHeaders(arHeaders).addDTActionsHeader();
    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.company.selected", this.index);
  }
}
</script>
